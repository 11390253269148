<template>
  <div class="filter--date-picker-component">
    <b-row>
      <b-col cols="6">
        <HeroCheckbox
          id="show-current-contract"
          v-model="showCurrentContract"
          label="Show Current Contract"
          @input="handleShowCurrentContract"
        />
      </b-col>
      <b-col cols="6">
        <HeroCheckbox
          id="compare-data"
          v-model="endableCompare"
          label="Compare Date"
          :disabled="showCurrentContract"
          @input="handleInputChange"
        />
      </b-col>
      <b-col v-show="endableCompare && !showCurrentContract" cols="6">
        <b-form-radio
          v-model="radioGroup"
          :value="1"
          :disabled="!endableCompare || showCurrentContract"
          class="ma-0 pa-0 justify-end"
          @change="handleOptionsChange"
        >
          Compare Cycle
        </b-form-radio>
        <HeroInputNumber
          id="compare-periods"
          v-model="period"
          label="Compare Periods"
          class="pt-1"
          :disabled="!endableCompare || showCurrentContract"
          @input="handleComparePeriodChange"
        />
      </b-col>
      <b-col v-show="!endableCompare && !showCurrentContract" cols="12">
        <CalendarComponent
          :value="datePicker"
          :disabled="endableCompare || showCurrentContract"
          display-format="dd/MM/yyyy"
          @onChange="handleChange"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import CalendarComponent from '../CalendarComponent/CalendarComponent.vue'

import './style.scss'

export default {
  name: 'DatePickerComponent',
  components: {
    HeroCheckbox,
    HeroInputNumber,
    CalendarComponent,
  },
  filters: {
    dateToISOString(value) {
      return new Date(value).toISOString()
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const defaultPeriod = 1
    const defaultEndableCompare = true
    return ({
      period: defaultPeriod,
      datePicker: [],
      displayFormat: 'DD/MM/YYYY',
      storeFormat: 'YYYY-MM-DD',
      drawer: false,
      classname: 'overflow-hidden',
      radioGroup: 1,
      periods: [2, 3, 4, 5],
      endableCompare: defaultEndableCompare,
      results: {
        endableCompare: defaultEndableCompare,
        compareBy: 'cycle',
        period: defaultPeriod,
        datePicker: [],
        showCurrentContract: false,
      },
      enum: {
        1: 'cycle',
        2: 'days',
        days: 2,
        cycle: 1,
      },
      showCurrentContract: false,
      disableOkButton: false,
    })
  },
  watch: {
    value() {
      if (!this.value) return
      this.initData()
    },
  },
  mounted() {
    if (this.value) this.initData()
  },
  methods: {
    getMainDrawerEleClasslist() {
      const { classList } = document.getElementsByClassName('main-filter-drawer')[0]
      return classList
    },
    handleOpenDrawer() {
      const classList = this.getMainDrawerEleClasslist()
      this.drawer = true
      setTimeout(() => {
        classList.add(this.classname)
      }, 100)
    },
    handleInputChange() {
      // this.endableCompare = checked
      this.results.endableCompare = this.endableCompare
      this.results.period = this.period
      this.handleOptionsChange(this.radioGroup)
    },
    handleComparePeriodChange() {
      const value = this.period
      const newvalue = value ? parseInt(value, 10) : 0

      this.disableOkButton = newvalue < 1 || newvalue > 60
      this.results = {
        ...this.results,
        period: this.disableOkButton ? 1 : this.period,
      }
      if (!this.disableOkButton) {
        this.handleOk()
      }
    },
    handleCancel() {
      const classList = this.getMainDrawerEleClasslist()
      classList.remove(this.classname)
      setTimeout(() => {
        this.period = this.disableOkButton ? this.value.period : this.value.period
        this.datePicker = this.value ? this.value.datePicker : []
        this.drawer = false
        this.disableOkButton = false
      }, 100)
    },
    handleOptionsChange(value) {
      this.results = {
        ...this.results,
        compareBy: this.enum[value],
      }
    },
    initData() {
      this.datePicker = this.value.datePicker
      this.radioGroup = this.enum[this.value.compareBy] || 1
      this.period = this.value.period || 2
      this.endableCompare = this.value.endableCompare
      this.showCurrentContract = this.value.showCurrentContract || false
      this.results = {
        ...this.results,
        ...this.value,
      }
    },
    handleChange(date) {
      this.endableCompare = false
      this.results.endableCompare = false
      this.datePicker = date
      this.handleOk()
    },
    handleOk() {
      const classList = this.getMainDrawerEleClasslist()
      classList.remove(this.classname)
      setTimeout(() => {
        const dateFormated = this.datePicker.map(([from, to]) => {
          const fromDate = new Date(from).toISOString()
          const toDate = new Date(to).toISOString()
          return [this.$moment(fromDate).format(this.storeFormat), this.$moment(toDate).format(this.storeFormat)]
        })
        this.results = {
          ...this.results,
          datePicker: dateFormated,
        }
        this.$emit('onChange', this.results)
        this.drawer = false
      }, 100)
    },
    handleShowCurrentContract(value) {
      this.showCurrentContract = value
      this.results = {
        ...this.results,
        showCurrentContract: value,
      }
      this.handleOk()
    },
  },
}
</script>
